<template>
  <div class="order">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>我的订单</div>
    </div>
    <van-tabs
      v-model="active"
      background="#f5f5f5"
      title-active-color="#0E3592"
      color="#0E3592"
      line-width="26"
      line-height="3"
      title-inactive-color="#666"
      @click="onClick"
    >
      <van-tab v-for="(item) in classifyList" :title="item" :key="item">
        <div class="content_item" v-for="childItem in List" :key="childItem.order_id">
          <div class="item_title">
            <div>商品名称</div>
            <div>{{childItem.goods_name}}</div>
          </div>
          <div class="item_center">
            <div class="item">
              <div class="item_left">积分&规格：</div>
              <div class="item_right">
                <span class="item_right_price">{{childItem.shop_price}}</span>
                <span class="item_right_num">/{{childItem.spu}}</span>
              </div>
            </div>
            <div class="item">
              <div class="item_left">下单号：</div>
              <div>{{childItem.order_sn}}</div>
            </div>
            <div class="item">
              <div class="item_left">下单时间：</div>
              <div>{{childItem.add_time}}</div>
            </div>
            <div class="item">
              <div class="item_left">订单状态：</div>
              <div
                :class="[childItem.shipping_status==1? 'wait_status':childItem.shipping_status==2?'delivery_status':'complete_status']"
              >{{childItem.shipping_status==1?"待发货":childItem.shipping_status==2?"已发货":"已完成"}}</div>
            </div>
             <div class="item">
              <div class="item_left">收货人姓名：</div>
              <div>{{childItem.consignee_name}}</div>
            </div>
             <div class="item">
              <div class="item_left">收货人联系方式：</div>
              <div>{{childItem.consignee_mobile}}</div>
            </div>
            <div class="item">
              <div class="item_left">收货地址：</div>
              <div>{{childItem.address}}</div>
            </div>
            <div class="item">
              <div class="item_left">备注：</div>
              <div>{{childItem.note}}</div>
            </div>
            <!-- -->
            <div class="item" v-show="childItem.shipping_status!=1" >
              <div class="item_left">物流号：</div>
              <div class="right_complete">
                <span class="complete_number">{{childItem.shipping_code}}</span>
                <span :data-clipboard-text="childItem.shipping_code" class="copy" @click="copy">复制</span>
              </div>
            </div>
          </div>
          <div class="item_footer">
            <div class="footer_left" v-show="childItem.remind_shipping==1">已提醒</div>
            <div
              class="footer_right"
              v-show="childItem.shipping_status==1&&childItem.remind_shipping==0"
              @click="handleAlert(childItem.order_id)"
            >提醒发货</div>
            <div
              class="footer_appeal"
              v-show="childItem.complain==0&&childItem.shipping_status!=1"
              @click="handleComplaint(childItem.order_id)"
            >申诉</div>
            <div
              class="footer_right"
              v-show="childItem.shipping_status==2"
              @click="handleConfirm(childItem.order_id)"
            >确认收货</div>
            <div class="footer_appeal_Have" v-show="childItem.complain==1">已申诉</div>
          </div>
        </div>
        <div class="none" v-show="(List instanceof Array)&&List.length==0">暂无商品</div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      active: 0,
      List: [],
      classifyList: []
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    // 提醒发货
    async handleAlert(orderId) {
      const data = await this.$api.remind({
        order_id: orderId
      });
      if (data.data.code === 1) {
        this.$notify({ type: "success", message: data.data.msg });
        this.handleGetOrderStatus();
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    //确认收货
    async handleConfirm(orderId) {
      const data = await this.$api.confirmReceipt({
        order_id: orderId
      });
      if (data.data.code === 1) {
        this.handleGetOrderStatus();
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data, "确认收货");
    },
    //申诉
    handleComplaint(orderId) {
      this.$router.push({
        path: "/complaint/index",
        query: {
          orderId
        }
      });
    },
    onClick(name, title) {
      console.log(name, title);
      this.handleGetOrderList(name);
    },
    async handleGetOrderStatus() {
      const data = await this.$api.getOrderStatus();
      console.log(data, "data");
      if (data.data.code === 1) {
        this.classifyList = data.data.data;
        this.handleGetOrderList(0);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetOrderList(status) {
      const data = await this.$api.getOrderList({
        shipping_status: status
      });
      console.log(data, "data");
      if (data.data.code === 1) {
        this.List = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.List = [];
      }
    },
    copy() {
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", e => {
        console.log("复制成功", e);
        this.$notify({ type: "success", message: "复制成功!" });
        // 释放内存
        clipboard.destroy();
      });
      // clipboard.on('error', e => {
      //   // 不支持复制
      //   this.$notify({ type: "success", message: "手机权限不支持复制功能" });

      //   // Toast({
      //   //   message: '手机权限不支持复制功能'
      //   // })
      //   console.log('该浏览器不支持自动复制')
      //   // 释放内存
      //   clipboard.destroy()
      // })
    }
  },
  mounted() {

    this.handleGetOrderStatus();
  }
};
</script>
<style lang="scss" scoped>
.order {
  width: 375px;
  height: auto;
  min-height: 100vh;
  background: #f5f5f5;
  padding-top: 40px;
  box-sizing: border-box;
  .content_item {
    width: 375px;
    min-height: 255px;
    // max-height: 291px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    .item_title {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;
      padding: 0 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #e5e5e5;
    }
    .item_center {
      width: 351px;
      margin: 0 auto;
      min-height: 158px;
      // max-height: 204px;
      font-size: 14px;
      line-height: 15px;
      border-bottom: 1px solid #e5e5e5;
      padding-top: 15px;
      color: #333;
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 21px;
        .item_left {
          color: rgba(102, 102, 102, 1);
        }
        .item_right {
          line-height: 14px;

          .item_right_price {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(254, 29, 29, 1);
            margin-right: 16px;
          }
          .item_right_num {
            color: #666;
          }
        }
        .wait_status {
          color: rgba(255, 99, 0, 1);
        }
        .delivery_status {
          color: rgba(0, 177, 0, 1);
        }
        .complete_status {
          color: rgba(153, 153, 153, 1);
        }
        .right_complete {
          color: rgba(50, 125, 251, 1);
          .complete_number {
            margin-right: 20px;
          }
        }
      }
    }
    .item_footer {
      display: flex;
      justify-content: flex-end;
      height: 50px;
      align-items: center;
      padding-right: 12px;
      box-sizing: border-box;
      .footer_left {
        width: 79px;
        height: 30px;
        background: rgba(216, 216, 216, 1);
        border-radius: 15px;
        font-size: 14px;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        text-align: center;
      }
      .footer_right {
        width: 90px;
        height: 30px;
        background: #0E3592;
        border-radius: 15px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        line-height: 30px;
        text-align: center;
        margin-left: 17px;
      }
      .footer_appeal {
        width: 79px;
        height: 30px;
        background: #0E3592;
        border-radius: 15px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        margin-left: 17px;
      }
      .footer_appeal_Have {
        width: 79px;
        height: 30px;
        background: rgba(255, 93, 93, 1);
        border-radius: 15px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }
    }
  }
  .none {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 100px;
  }
}
</style>
